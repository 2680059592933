import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';
import { Line } from 'react-chartjs-2';
import { Calendar, Clock, TrendingUp } from 'lucide-react';
import { STAKING_PERIODS } from '../../web3/config';

const TokenCard = ({ symbol, token, isSelected, onSelect, duration, index, setHoveredToken }) => {
  const [selectedDuration, setSelectedDuration] = useState(duration || STAKING_PERIODS.MONTHLY);

  // 3D Tilt efekti
  const [{ xys }, api] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 }
  }));

  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1
  ];

  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

  // Price chart data
  const chartData = {
    labels: Array.from({ length: 12 }).map((_, i) => i),
    datasets: [{
      data: Array.from({ length: 12 }).map(() => Math.random() * 100),
      fill: true,
      borderColor: isSelected ? '#F0B90B' : '#9CA3AF',
      borderWidth: 2,
      pointRadius: 0,
      tension: 0.4,
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 100);
        gradient.addColorStop(0, isSelected ? 'rgba(240, 185, 11, 0.2)' : 'rgba(156, 163, 175, 0.2)');
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
        return gradient;
      }
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false } },
    scales: { x: { display: false }, y: { display: false } }
  };

  return (
    <animated.div
      onMouseMove={({ clientX: x, clientY: y }) => api.start({ xys: calc(x, y) })}
      onMouseLeave={() => api.start({ xys: [0, 0, 1] })}
      style={{ transform: xys.to(trans) }}
      className="group"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: index * 0.1 }}
        onClick={() => onSelect(symbol, selectedDuration)}
        className={`relative overflow-hidden p-6 rounded-2xl transition-all duration-300 cursor-pointer
          ${isSelected 
            ? 'bg-gradient-to-br from-yellow-50 to-orange-50 dark:from-yellow-900/20 dark:to-orange-900/20 border-2 border-bnb-yellow'
            : 'bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 hover:border-bnb-yellow dark:hover:border-bnb-yellow'
          }
        `}
      >
        {/* Token Info */}
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3">
            <div className="relative">
              <img
                src={token.logo}
                alt={symbol}
                className="w-12 h-12 rounded-full"
              />
              <motion.div
                animate={{
                  scale: isSelected ? [1, 1.2, 1] : 1
                }}
                transition={{ duration: 0.3 }}
                className={`absolute -top-1 -right-1 w-4 h-4 rounded-full border-2 border-white dark:border-gray-800
                  ${isSelected ? 'bg-green-500' : 'bg-gray-200 dark:bg-gray-700'}`}
              />
            </div>
            <div>
              <h3 className="font-bold text-lg text-gray-900 dark:text-white">
                {symbol}
              </h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {token.name}
              </p>
            </div>
          </div>
          <div>
            <span className={`px-3 py-1 rounded-full text-xs font-medium
              ${isSelected
                ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-300'
                : 'bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-300'}
            `}>
              {token.category}
            </span>
          </div>
        </div>

        {/* Chart */}
        <div className="h-24 mb-4">
          <Line data={chartData} options={chartOptions} />
        </div>

        {/* Duration Selector */}
        <div className="mb-4">
          <div className="flex gap-2">
            {Object.entries(STAKING_PERIODS).map(([key, period]) => (
              <motion.button
                key={key}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedDuration(period);
                }}
                className={`flex-1 py-2 px-3 rounded-xl flex items-center justify-center gap-2 transition-all
                  ${selectedDuration === period
                    ? 'bg-bnb-yellow text-black font-semibold shadow-lg'
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
              >
                <Clock className="w-4 h-4" />
                <span className="text-sm font-medium">
                  {key === 'MONTHLY' ? '30D' : '365D'}
                </span>
              </motion.button>
            ))}
          </div>
        </div>

        {/* Stats */}
        <div className="grid grid-cols-2 gap-4">
          <div className="p-3 rounded-xl bg-gray-50 dark:bg-gray-700/50">
            <p className="text-sm text-gray-500 dark:text-gray-400">APR</p>
            <p className="text-lg font-bold text-bnb-yellow">
              {token.apr[selectedDuration]}%
            </p>
          </div>
          <div className="p-3 rounded-xl bg-gray-50 dark:bg-gray-700/50">
            <p className="text-sm text-gray-500 dark:text-gray-400">Min Stake</p>
            <p className="text-lg font-bold text-gray-900 dark:text-white">
              {token.minAmount[selectedDuration]} {symbol}
            </p>
          </div>
        </div>
        <div className="absolute top-2 right-2 flex flex-col gap-1">
          {token.isNative && (
            <span className="px-2 py-1 text-xs font-medium bg-yellow-100 text-yellow-800 rounded-full">
              Native
            </span>
          )}
          {token.convertibleToBNB && (
            <span className="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
              {token.conversionNote}
            </span>
          )}
        </div>
      </motion.div>
    </animated.div>
  );
};

export default TokenCard;